.text-line {
  height: 20vh;
  stroke-dasharray: 540;
  stroke-dashoffset: 540;
  animation: dash 3s linear forwards, filling 3s ease-in forwards;
  font-size: 184px;
}

.text-line text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-style: normal;
}

@media only screen and (max-width: 600px) {
  .text-line {
    font-size: 64px;
    max-width: 100%;
    height: 8vh;
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    stroke-width: 2;
    display: flex;
    animation: dash 6s linear forwards, filling 6s ease-in forwards;
    justify-content: center;
    cursor: pointer;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  0%,
  90% {
    fill: #000;
    fill-opacity: 0;
  }
  100% {
    fill: #000;
    fill-opacity: 1;
  }
}
