@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap');

$desktop: 1220px;
$laptop: 1024px;
$tablet: 768px;
$phone: 480px;
$red: #1c1c1c;
$black: #000000;
* {
   box-sizing: border-box;
}

html,
body {
   padding: 0;
   margin: 0;
   font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.App {
   max-width: 100vw;
   width: 100%;
   margin: 0 auto;
   height: 100%;
   position: relative;

   // cursor: none;

   .back-to-top {
      position: fixed;
      bottom: 1.5rem;
      right: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      background-color: black;
      // border-radius: 50%;
      z-index: 1000;
      transition: all 0.4s;
      text-decoration-line: none;

      &:hover {
         transform: scale(1.1);
      }
      .back-to-top__image {
         // height: 70%;
         color: rgb(255, 255, 255);
         font-size: 1.1rem;
      }
   }
}
.cursor {
   top: -12px;
   left: -12px;
   pointer-events: none;
   overflow: hidden;
   opacity: 1;
   position: absolute;
   z-index: 100;
   border-radius: 50%;
   border: 1px solid rgb(255, 255, 255);
}

header {
   display: flex;
   justify-content: center;

   .container {
      position: fixed;
      z-index: 10;
      max-width: 90vw;
      width: 100%;
      margin: 0px auto;

      @media (max-width: $desktop) {
         max-width: 90vw;
      }
      @media (max-width: $laptop) {
         max-width: 90vw;
      }
      @media (max-width: $tablet) {
         max-width: 90vw;
      }
      @media (max-width: $phone) {
         max-width: 90vw;
      }
   }
}
